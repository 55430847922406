/*@font-face {
    font-family: 'Open Sans Hebrew';
    font-style: italic;
    font-weight: 300;
    src: url(opensanshebrew-lightitalic-webfont.eot);
    src: url(opensanshebrew-lightitalic-webfont.eot?#iefix) format('embedded-opentype'),
    url(opensanshebrew-lightitalic-webfont.woff) format('woff'),
    url(opensanshebrew-lightitalic-webfont.ttf) format('truetype');
}*/
@font-face {
    font-family: 'Open Sans Hebrew';
    font-style: normal;
    font-weight: 300;
    src: url(opensanshebrew-light-webfont.eot);
    src: url(opensanshebrew-light-webfont.eot?#iefix) format('embedded-opentype'),
    url(opensanshebrew-light-webfont.woff) format('woff'),
    url(opensanshebrew-light-webfont.ttf) format('truetype');
}
/*@font-face {
    font-family: 'Open Sans Hebrew';
    font-style: italic;
    font-weight: 400;
    src: url(opensanshebrew-italic-webfont.eot);
    src: url(opensanshebrew-italic-webfont.eot?#iefix) format('embedded-opentype'),
    url(opensanshebrew-italic-webfont.woff) format('woff'),
    url(opensanshebrew-italic-webfont.ttf) format('truetype');
}*/
@font-face {
    font-family: 'Open Sans Hebrew';
    font-style: normal;
    font-weight: 400;
    src: url(opensanshebrew-regular-webfont.eot);
    src: url(opensanshebrew-regular-webfont.eot?#iefix) format('embedded-opentype'),
    url(opensanshebrew-regular-webfont.woff) format('woff'),
    url(opensanshebrew-regular-webfont.ttf) format('truetype');
}
@font-face {
    font-family: 'Open Sans Hebrew';
    font-style: italic;
    font-weight: 700;
    src: url(opensanshebrew-bolditalic-webfont.eot);
    src: url(opensanshebrew-bolditalic-webfont.eot?#iefix) format('embedded-opentype'),
    url(opensanshebrew-bolditalic-webfont.woff) format('woff'),
    url(opensanshebrew-bolditalic-webfont.ttf) format('truetype');
}
@font-face {
    font-family: 'Open Sans Hebrew';
    font-style: normal;
    font-weight: 700;
    src: url(opensanshebrew-bold-webfont.eot);
    src: url(opensanshebrew-bold-webfont.eot?#iefix) format('embedded-opentype'),
    url(opensanshebrew-bold-webfont.woff) format('woff'),
    url(opensanshebrew-bold-webfont.ttf) format('truetype');
}
/*@font-face {
    font-family: 'Open Sans Hebrew';
    font-style: italic;
    font-weight: 800;
    src: url(opensanshebrew-extrabold-webfont.eot);
    src: url(opensanshebrew-extrabold-webfont.eot?#iefix) format('embedded-opentype'),
    url(opensanshebrew-extrabold-webfont.woff) format('woff'),
    url(opensanshebrew-extrabold-webfont.ttf) format('truetype');
}*/
@font-face {
    font-family: 'Open Sans Hebrew';
    font-style: normal;
    font-weight: 800;
    src: url(opensanshebrew-extrabold-webfont.eot);
    src: url(opensanshebrew-extrabold-webfont.eot?#iefix) format('embedded-opentype'),
    url(opensanshebrew-extrabold-webfont.woff) format('woff'),
    url(opensanshebrew-extrabold-webfont.ttf) format('truetype');
}